@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Thin.483bdd8a.eot);
    src: local('Graphik Thin'), local('Graphik-Thin'),
        url(/static/media/Graphik-Thin.483bdd8a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Thin.88a04f46.woff2) format('woff2'),
        url(/static/media/Graphik-Thin.87cb6e74.woff) format('woff'),
        url(/static/media/Graphik-Thin.cb8fee6e.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-ThinItalic.5e9bbabf.eot);
    src: local('Graphik Thin Italic'), local('Graphik-ThinItalic'),
        url(/static/media/Graphik-ThinItalic.5e9bbabf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-ThinItalic.2c8c9acd.woff2) format('woff2'),
        url(/static/media/Graphik-ThinItalic.9d2ba30e.woff) format('woff'),
        url(/static/media/Graphik-ThinItalic.88fbe140.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik Super';
    src: url(/static/media/Graphik-SuperItalic.6184902a.eot);
    src: local('Graphik Super Italic'), local('Graphik-SuperItalic'),
        url(/static/media/Graphik-SuperItalic.6184902a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-SuperItalic.f4b1ef94.woff2) format('woff2'),
        url(/static/media/Graphik-SuperItalic.9e54b411.woff) format('woff'),
        url(/static/media/Graphik-SuperItalic.0de2388a.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Medium.a87273db.eot);
    src: local('Graphik Medium'), local('Graphik-Medium'),
        url(/static/media/Graphik-Medium.a87273db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Medium.2b31c0c3.woff2) format('woff2'),
        url(/static/media/Graphik-Medium.d9746847.woff) format('woff'),
        url(/static/media/Graphik-Medium.e8f4fa13.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Black.6fc042a3.eot);
    src: local('Graphik Black'), local('Graphik-Black'),
        url(/static/media/Graphik-Black.6fc042a3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Black.932848e7.woff2) format('woff2'),
        url(/static/media/Graphik-Black.a23100ef.woff) format('woff'),
        url(/static/media/Graphik-Black.ef73d694.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Semibold.f55a8927.eot);
    src: local('Graphik Semibold'), local('Graphik-Semibold'),
        url(/static/media/Graphik-Semibold.f55a8927.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Semibold.f9277a68.woff2) format('woff2'),
        url(/static/media/Graphik-Semibold.a439eb2d.woff) format('woff'),
        url(/static/media/Graphik-Semibold.61db2776.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik Super';
    src: url(/static/media/Graphik-Super.ffec1304.eot);
    src: local('Graphik Super'), local('Graphik-Super'),
        url(/static/media/Graphik-Super.ffec1304.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Super.867a2a2b.woff2) format('woff2'),
        url(/static/media/Graphik-Super.7979eace.woff) format('woff'),
        url(/static/media/Graphik-Super.066a8a2c.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-ExtralightItalic.e50e6593.eot);
    src: local('Graphik Extralight Italic'), local('Graphik-ExtralightItalic'),
        url(/static/media/Graphik-ExtralightItalic.e50e6593.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-ExtralightItalic.c46fb626.woff2) format('woff2'),
        url(/static/media/Graphik-ExtralightItalic.b35878c0.woff) format('woff'),
        url(/static/media/Graphik-ExtralightItalic.de80d33d.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-BlackItalic.5731df15.eot);
    src: local('Graphik Black Italic'), local('Graphik-BlackItalic'),
        url(/static/media/Graphik-BlackItalic.5731df15.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-BlackItalic.fdde9737.woff2) format('woff2'),
        url(/static/media/Graphik-BlackItalic.e945c30b.woff) format('woff'),
        url(/static/media/Graphik-BlackItalic.73fc313c.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-RegularItalic.31e9417f.eot);
    src: local('Graphik Regular Italic'), local('Graphik-RegularItalic'),
        url(/static/media/Graphik-RegularItalic.31e9417f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-RegularItalic.6706d3d4.woff2) format('woff2'),
        url(/static/media/Graphik-RegularItalic.0eabd6bc.woff) format('woff'),
        url(/static/media/Graphik-RegularItalic.c72c2f26.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Light.e9db6be6.eot);
    src: local('Graphik Light'), local('Graphik-Light'),
        url(/static/media/Graphik-Light.e9db6be6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Light.963df1b8.woff2) format('woff2'),
        url(/static/media/Graphik-Light.358ffe0c.woff) format('woff'),
        url(/static/media/Graphik-Light.cadce084.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Bold.f92eb05e.eot);
    src: local('Graphik Bold'), local('Graphik-Bold'),
        url(/static/media/Graphik-Bold.f92eb05e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Bold.085031af.woff2) format('woff2'),
        url(/static/media/Graphik-Bold.1cfe3bed.woff) format('woff'),
        url(/static/media/Graphik-Bold.bf600a6e.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Regular.875c3353.eot);
    src: local('Graphik Regular'), local('Graphik-Regular'),
        url(/static/media/Graphik-Regular.875c3353.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Regular.42a9b773.woff2) format('woff2'),
        url(/static/media/Graphik-Regular.fb0cb273.woff) format('woff'),
        url(/static/media/Graphik-Regular.666f1412.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-BoldItalic.aa1060c1.eot);
    src: local('Graphik Bold Italic'), local('Graphik-BoldItalic'),
        url(/static/media/Graphik-BoldItalic.aa1060c1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-BoldItalic.d8c1f2cf.woff2) format('woff2'),
        url(/static/media/Graphik-BoldItalic.a5053e71.woff) format('woff'),
        url(/static/media/Graphik-BoldItalic.ee464a59.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-SemiboldItalic.fa1de787.eot);
    src: local('Graphik Semibold Italic'), local('Graphik-SemiboldItalic'),
        url(/static/media/Graphik-SemiboldItalic.fa1de787.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-SemiboldItalic.06db8626.woff2) format('woff2'),
        url(/static/media/Graphik-SemiboldItalic.b3371d53.woff) format('woff'),
        url(/static/media/Graphik-SemiboldItalic.afc0bd74.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-LightItalic.db654ff9.eot);
    src: local('Graphik Light Italic'), local('Graphik-LightItalic'),
        url(/static/media/Graphik-LightItalic.db654ff9.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-LightItalic.b0b09aa8.woff2) format('woff2'),
        url(/static/media/Graphik-LightItalic.b22fd149.woff) format('woff'),
        url(/static/media/Graphik-LightItalic.0cce7e3d.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-Extralight.c6e9419a.eot);
    src: local('Graphik Extralight'), local('Graphik-Extralight'),
        url(/static/media/Graphik-Extralight.c6e9419a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-Extralight.99af7ed2.woff2) format('woff2'),
        url(/static/media/Graphik-Extralight.86ffe48d.woff) format('woff'),
        url(/static/media/Graphik-Extralight.20ce2696.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url(/static/media/Graphik-MediumItalic.00c7e913.eot);
    src: local('Graphik Medium Italic'), local('Graphik-MediumItalic'),
        url(/static/media/Graphik-MediumItalic.00c7e913.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Graphik-MediumItalic.b5682efb.woff2) format('woff2'),
        url(/static/media/Graphik-MediumItalic.1640d786.woff) format('woff'),
        url(/static/media/Graphik-MediumItalic.8815b264.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}


/*"Reem Kufi",*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0 auto;
  font-family: "Graphik", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

.arrow-link {
  width: 8px;
  margin-top: 2px;
}

@media screen and (max-width: 1050px) {
  .main-container {
    padding-left: 20px;
  }

  .form-container {
    min-width: 100%;
  }

  .form-input {
    font-size: 23px;
  }

  .form-textarea {
    font-size: 23px;
  }

  .form-submit {
    font-size: 23px;
  }
}

.main-container {
  margin: 0 auto;
  width: 1050px;
  /* width: 100%; */
}

.main-header {
  height: 65px;
  /* padding-top: 37px; */
  display: flex;
  align-items: flex-end;
  /* background-color: #273452; */
  /* background-color: #e5e6e8; */
  margin-bottom: 1px;
}

.header-container {
  /* margin-top: auto;
  margin-bottom: auto; */
  /* padding-left: 25px; */
  margin-bottom: 12px;
}

.logo-section {
  display: inline-block;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.logo-text {
  color: #000;
  font-size: 25px;
  font-weight: 700;
}

.main-section {
  /* background-color: black; */
  /* height: 450px; */
  height: 410px;
  padding: 0 25px;
  /* margin-top: 46px; */
  background-image: linear-gradient(rgb(243 242 242 / 3%), rgb(14 14 14 / 59%)),
    url(/static/media/main_bg.897195b3.jpg);
  /* background-image: url("./potter.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: blue; */
  background-position: center;
  min-width: 1050px;
}

.main-tagline {
  color: #fff;
  /* font-size: 37px; */
  /* font-weight: 400; */
  /* padding-top: 320px; */
  font-size: 56px;
  font-weight: 600;
  padding-top: 210px;
  letter-spacing: 1px;
  /* line-height: 46px; */
}

.about-teaser-section {
  /* padding: 0 25px; */
  padding-top: 46px;
  width: 70%;
}

.about-teaser-headline {
  color: #000;
  /* font-size: 65px; */
  font-size: 42px;
  font-weight: 800;
}

.about-teaser-description {
  font-weight: 400;
  font-size: 31px;
  line-height: 43px;
  padding-top: 15px;
  /* color: #808080; */
}

.service-section {
  padding-top: 46px;
  padding-bottom: 26px;
}

.service-points-area {
  padding-bottom: 46px;
}

.service-point-tagline {
  /* font-size: 28px; */
  font-size: 25px;
  /* padding-bottom: 6px; */
  padding-bottom: 11px;
  /* font-weight: 400; */
  font-weight: 700;
  /* color: #585755; */
}

.service-point-description {
  /* font-size: 24px; */
  /* font-size: 22px; */
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  width: 70%;
}

.career-teaser-section {
  /* background-color: black; */
  background-image: linear-gradient(rgb(243 242 242 / 3%), rgb(14 14 14 / 59%)),
    url(/static/media/career.589e25f0.jpg);
  height: 350px;
  margin-bottom: 165px;
  padding-left: 25px;
  background-size: cover;
  background-position: center;
}

.career-teaser-tagline {
  color: #fff;
  padding-top: 230px;
  font-size: 32px;
  font-weight: 500;
  padding-bottom: 12px;
}

.career-link-container {
  display: flex;
  align-items: center;
}

.career-teaser-link {
  color: #e5e6e8;
  font-size: 18px;
}

.contact-section {
  text-align: center;
  /* padding-top: 12px; */
  padding-bottom: 126px;
}

.contact-tagline {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 12px;
  color: #000;
}

.contact-description {
  font-size: 24px;
  font-weight: 400;
  color: #000;
}

.contact-link {
  display: block;
  padding-top: 23px;
  font-size: 18px;
  color: rgb(14 14 14 / 59%);
}

.footer-section {
  border-top: 3px solid #e5e6e8;
  padding-bottom: 56px;
}

.footer-label {
  padding-top: 12px;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 56px;
}

.form-input {
  margin-bottom: 12px;
  font-size: 17px;
  padding: 6px 12px;
  outline: none;
}

.form-textarea {
  font-size: 17px;
  font-family: "Graphik";
  padding-left: 12px;
  padding-top: 6px;
  margin-bottom: 12px;
  min-height: 80px;
  outline: none;
}

.form-submit {
  font-size: 17px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  border: 2px solid transparent;
  font-family: "Graphik";
}

.form-submit--default {
  background: #e5e6e8;
}

.form-submit:hover {
  background: #d0d1d3;
}

.form-submit--submited {
  background: #d0d1d3;
  cursor: default;
}

.form-submited {
  padding-bottom: 12px;
}

input[aria-invalid="true"] {
  border: 2px solid red;
}

textarea[aria-invalid="true"] {
  border: 2px solid red;
}

.main-careers-container {
  width: 1250px;
  margin-left: auto;
}

.career-container-content-wrapper {
  min-height: 100vh;
}

.subheader-careers {
  border-bottom: 2px solid #e5e6e8;
  padding-bottom: 29px;
}

.subheader-careers-content-wraper {
  width: 1050px;
  margin-left: 0;
}

.careers-headline {
  font-size: 24px;
}

.careers-description {
  padding-top: 14px;
  font-size: 17px;
  line-height: 1.5;
}

.section-positions {
  padding-top: 65px;
}

.position-title {
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 37px;
}

