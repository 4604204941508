.main-container {
  margin: 0 auto;
  width: 1050px;
  /* width: 100%; */
}

.main-header {
  height: 65px;
  /* padding-top: 37px; */
  display: flex;
  align-items: flex-end;
  /* background-color: #273452; */
  /* background-color: #e5e6e8; */
  margin-bottom: 1px;
}

.header-container {
  /* margin-top: auto;
  margin-bottom: auto; */
  /* padding-left: 25px; */
  margin-bottom: 12px;
}

.logo-section {
  display: inline-block;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.logo-text {
  color: #000;
  font-size: 25px;
  font-weight: 700;
}

.main-section {
  /* background-color: black; */
  /* height: 450px; */
  height: 410px;
  padding: 0 25px;
  /* margin-top: 46px; */
  background-image: linear-gradient(rgb(243 242 242 / 3%), rgb(14 14 14 / 59%)),
    url("./main_bg.jpg");
  /* background-image: url("./potter.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: blue; */
  background-position: center;
  min-width: 1050px;
}

.main-tagline {
  color: #fff;
  /* font-size: 37px; */
  /* font-weight: 400; */
  /* padding-top: 320px; */
  font-size: 56px;
  font-weight: 600;
  padding-top: 210px;
  letter-spacing: 1px;
  /* line-height: 46px; */
}

.about-teaser-section {
  /* padding: 0 25px; */
  padding-top: 46px;
  width: 70%;
}

.about-teaser-headline {
  color: #000;
  /* font-size: 65px; */
  font-size: 42px;
  font-weight: 800;
}

.about-teaser-description {
  font-weight: 400;
  font-size: 31px;
  line-height: 43px;
  padding-top: 15px;
  /* color: #808080; */
}

.service-section {
  padding-top: 46px;
  padding-bottom: 26px;
}

.service-points-area {
  padding-bottom: 46px;
}

.service-point-tagline {
  /* font-size: 28px; */
  font-size: 25px;
  /* padding-bottom: 6px; */
  padding-bottom: 11px;
  /* font-weight: 400; */
  font-weight: 700;
  /* color: #585755; */
}

.service-point-description {
  /* font-size: 24px; */
  /* font-size: 22px; */
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  width: 70%;
}

.career-teaser-section {
  /* background-color: black; */
  background-image: linear-gradient(rgb(243 242 242 / 3%), rgb(14 14 14 / 59%)),
    url("./career.jpg");
  height: 350px;
  margin-bottom: 165px;
  padding-left: 25px;
  background-size: cover;
  background-position: center;
}

.career-teaser-tagline {
  color: #fff;
  padding-top: 230px;
  font-size: 32px;
  font-weight: 500;
  padding-bottom: 12px;
}

.career-link-container {
  display: flex;
  align-items: center;
}

.career-teaser-link {
  color: #e5e6e8;
  font-size: 18px;
}

.contact-section {
  text-align: center;
  /* padding-top: 12px; */
  padding-bottom: 126px;
}

.contact-tagline {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 12px;
  color: #000;
}

.contact-description {
  font-size: 24px;
  font-weight: 400;
  color: #000;
}

.contact-link {
  display: block;
  padding-top: 23px;
  font-size: 18px;
  color: rgb(14 14 14 / 59%);
}

.footer-section {
  border-top: 3px solid #e5e6e8;
  padding-bottom: 56px;
}

.footer-label {
  padding-top: 12px;
}
