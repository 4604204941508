.form-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 56px;
}

.form-input {
  margin-bottom: 12px;
  font-size: 17px;
  padding: 6px 12px;
  outline: none;
}

.form-textarea {
  font-size: 17px;
  font-family: "Graphik";
  padding-left: 12px;
  padding-top: 6px;
  margin-bottom: 12px;
  min-height: 80px;
  outline: none;
}

.form-submit {
  font-size: 17px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  border: 2px solid transparent;
  font-family: "Graphik";
}

.form-submit--default {
  background: #e5e6e8;
}

.form-submit:hover {
  background: #d0d1d3;
}

.form-submit--submited {
  background: #d0d1d3;
  cursor: default;
}

.form-submited {
  padding-bottom: 12px;
}

input[aria-invalid="true"] {
  border: 2px solid red;
}

textarea[aria-invalid="true"] {
  border: 2px solid red;
}
