.main-careers-container {
  width: 1250px;
  margin-left: auto;
}

.career-container-content-wrapper {
  min-height: 100vh;
}

.subheader-careers {
  border-bottom: 2px solid #e5e6e8;
  padding-bottom: 29px;
}

.subheader-careers-content-wraper {
  width: 1050px;
  margin-left: 0;
}

.careers-headline {
  font-size: 24px;
}

.careers-description {
  padding-top: 14px;
  font-size: 17px;
  line-height: 1.5;
}

.section-positions {
  padding-top: 65px;
}

.position-title {
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 37px;
}
