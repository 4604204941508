/*"Reem Kufi",*/
@import url("fonts/graphik/stylesheet.css");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0 auto;
  font-family: "Graphik", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

.arrow-link {
  width: 8px;
  margin-top: 2px;
}

@media screen and (max-width: 1050px) {
  .main-container {
    padding-left: 20px;
  }

  .form-container {
    min-width: 100%;
  }

  .form-input {
    font-size: 23px;
  }

  .form-textarea {
    font-size: 23px;
  }

  .form-submit {
    font-size: 23px;
  }
}
